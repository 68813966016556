import axios, { AxiosError } from "axios";
import queryString from "query-string";

import { createStyles, withStyles } from "@material-ui/core/styles";
import { Container, WithStyles } from "@material-ui/core";

import React from "react";
import { useLocation } from "react-router-dom";
import { QueryKey, useQuery } from "react-query";
import LoadingComponent from "../loading/loading.component";
import ErrorComponent from "../error/error.component";
import RedirectComponent from "../redirect/redirect.component";

function useQueryParam() {
  return queryString.parse(useLocation().search);
}

const styles = createStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Roboto, serif",
    textAlign: "center",
    margin: "10px",
    padding: "5px",
  },
});

interface PrismAuthenticationQueryParams {
  key: string;
  peo_id: string;
  redirectUrl: string;
  widgetId: string;
}

interface PrismAuthenticationResponse {
  token: string;
  url: string;
}

async function authenticateQuery(
  _key: QueryKey,
  query: PrismAuthenticationQueryParams
) {
  const response = await axios.get<PrismAuthenticationResponse>("api/prism", {
    params: query,
  });
  return response.data;
}

function PrismComponent(props: WithStyles) {
  const query = useQueryParam();
  const feature = query["feature"];
  const key = query["key"];
  const peo_id = query["peo_id"];
  const redirectUrl = query["redirectUrl"];
  const widgetId = query["widgetId"];

  const { data, isLoading, error } = useQuery<PrismAuthenticationResponse, AxiosError>(
    [
      "prism",
      {
        feature: feature,
        key: key,
        peo_id: peo_id,
        redirectUrl: redirectUrl,
        widgetId: widgetId,
      },
    ],
    authenticateQuery
  );

  return (
    <div className={props.classes.root}>
      <div>
        <Container>
          <div>
            {isLoading ? (
              <LoadingComponent message="Authenticating..." />
            ) : error ? (
              <ErrorComponent error={error} />
            ) : data ? (
              <RedirectComponent token={data.token} url={data.url} />
            ) : null}
          </div>
        </Container>
      </div>
    </div>
  );
}

export default withStyles(styles)(PrismComponent);
