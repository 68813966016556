import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import { AxiosError } from "axios";

const styles = createStyles({
  root: {
    margin: "10px",
  },
  body1: {
    margin: "10px",
  },
});

function getMessage(error: AxiosError) {
  if (!error.response || !error.response.data) {
    return "Problem Loading Data";
  }

  if (typeof error.response.data !== "string") {
    return "Problem Loading Data";
  }

  return error.response.data;
}

function ErrorComponent(props: { error: AxiosError }) {
  const message = getMessage(props.error);
  return (
    <Container>
      <ErrorIcon color="error" fontSize="large" />
      <Typography color="error">{message}</Typography>
    </Container>
  );
}

export default withStyles(styles)(ErrorComponent);
