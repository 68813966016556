import React from "react";
import Loader from "react-loader-spinner";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";

const styles = createStyles({
  root: {
    margin: "10px",
  },
  body1: {
    margin: "10px",
  },
});

function LoadingComponent(props: { message: string }) {
  return (
    <Container>
      <Loader type="Watch" color="#00BFFF" height={60} width={60} />
      <Typography>{props.message}</Typography>
    </Container>
  );
}

export default withStyles(styles)(LoadingComponent);
