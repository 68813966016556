import React, { ReactElement } from "react";
import { ReactQueryDevtools } from "react-query-devtools";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import PrismComponent from "./prism/prism.component";

export default function App(): ReactElement {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/prism">
            <PrismComponent />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
      <ReactQueryDevtools initialIsOpen />
    </Router>
  );
}

function Home() {
  return <h2>Home</h2>;
}
