import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import LoadingComponent from "../loading/loading.component";

const styles = createStyles({
  root: {
    margin: "10px",
  },
  body1: {
    margin: "10px",
  },
});


interface RedirectComponentsProps {
  url: string;
  token: string;
}

function RedirectComponent(props: RedirectComponentsProps) {
  const message = `Redirecting to ${props.url}`;
  const redirectUrl = `${props.url}#token=${props.token}`

  window.location.replace(redirectUrl);
  return (
    <Container>
      <LoadingComponent message={message} />
    </Container>
  );
}

export default withStyles(styles)(RedirectComponent);
